import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import InputBase from '@mui/material/InputBase';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import LogoImage from "./../assets/headstart-logo.png";
import LogoImageOnWhite from "./../assets/headstart-logo-onwhite.jpeg";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import IconCancel from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '12ch',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  '&:hover': {
    textDecoration: 'underline',
  }
}));


export default class SiteAppBar extends React.Component {

  state = { mode: "normal" }

  closeClicked = (e) => {
    this.setState({ mode: "normal" });
  }

  searchClicked = (e) => {
    this.setState({ mode: "search" });
  }

  menuClicked = (e) => {
    this.setState({ mode: "menu" });
  }

  render() {
    const menuDisplay = this.state.mode === "menu" ? "flex" : "none";
    const closeButtonDisplay = this.state.mode === "normal" ? "none" : "flex";
    const menuButtonDisplay = this.state.mode === "normal" ? "flex" : "none";
    const searchButtonDisplay = this.state.mode === "normal" ? "flex" : "none";
    return (
      <>
        <div className="printOnly">
          <Container  >
            <img src={LogoImageOnWhite} height="50" alt="headSTART logo"></img>
          </Container>
        </div>

        <div className="noPrint">
          <AppBar position="static" sx={{ color: 'white' }} className="noPrint">
            <Container  >
              <Toolbar disableGutters={true}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Box sx={{ display: 'flex', pt: 0, pb: 1, flexGrow: 3 }} >
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      sx={{ flexGrow: 1, mt: 2, display: { sm: 'block' } }}
                    >
                      <Link href="/">
                        <img src={LogoImage} height="50" alt="headSTART logo"></img>
                      </Link>
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 0, alignItems: 'center', display: { xs: 'none', md: 'flex' } }} >
                    <ButtonGroup variant="text" >
                      <NavButton href="/worldviews">Worldviews</NavButton>
                      <NavButton href="/design">Design</NavButton>
                      <NavButton href="/cells">Cells</NavButton>
                      <NavButton href="/investigate" >Investigate&nbsp;Further</NavButton>
                    </ButtonGroup>
                  </Box>
                  <Box sx={{ flexGrow: 0, color: 'white', alignItems: 'center', display: { xs: 'none', md: 'flex' } }} >

                    <Search sx={{ height: "40px" }}>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <form action="/search">
                        <StyledInputBase
                          placeholder="Search…"
                          name="q"
                          inputProps={{ 'aria-label': 'search' }}
                        />
                      </form>
                    </Search>


                  </Box>

                  <Box sx={{ flexGrow: 0, alignItems: 'center', display: { xs: 'flex', md: 'none' } }} >

                    <IconButton sx={{ display: closeButtonDisplay }} size="large" aria-label="close" color="inherit" onClick={this.closeClicked}>
                      <IconCancel />
                    </IconButton>
                    <IconButton sx={{ display: searchButtonDisplay }} size="large" aria-label="search" color="inherit" onClick={this.searchClicked}>
                      <SearchIcon />
                    </IconButton>
                    <IconButton sx={{ display: menuButtonDisplay }} size="large" aria-label="menu" color="inherit" onClick={this.menuClicked}>
                      <MenuIcon />
                    </IconButton>



                  </Box>
                </Box>

              </Toolbar>

            </Container>

          </AppBar>
          <Container>
            <Collapse in={this.state.mode === "menu"}>
              <ButtonGroup orientation="vertical" variant="text" sx={{ mt: 1, width: "100%", display: { xs: menuDisplay, md: 'none' } }} >
                <Button href="/worldviews">Impact of Worldviews</Button>
                <Button href="/design">Design</Button>
                <Button href="/cells">Cell Biology</Button>
                <Button href="/investigate" >Investigate&nbsp;Further</Button>
              </ButtonGroup>

            </Collapse>
          </Container>
          <Collapse in={this.state.mode === "search"}>
            <form action="/search">
              <Container>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', color: 'black' }}>
                  <Box sx={{ display: 'flex', pt: 0, pb: 1, flexGrow: 3 }} >
                    <TextField variant="outlined"
                      size="small"
                      name="q"
                      placeholder="Search..."
                      sx={{ pr: 1, mt: 1, width: '100%' }}
                      inputProps={{ 'aria-label': 'search' }}
                      
                    />
                  </Box>

                  <Box sx={{ color: 'white', alignItems: 'center', display: { xs: 'flex', md: 'flex' } }} >

                    <Button type="submit" variant="contained" sx={{ color: 'white' }} >
                      <SearchIcon />
                    </Button>


                  </Box>
                </Box>

              </Container>

            </form>
          </Collapse>



        </div >
      </>
    )
  }
}
