import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import SiteAppBar from './SiteAppBar.js';
import Typography from '@mui/material/Typography';
import { CookiesProvider } from "react-cookie";

const theme2 = createTheme();
theme2.palette.primary.main = "#31A8F7";
theme2.palette.primary.dark = "#088DE5";
theme2.palette.primary.light = "#6AC0FA";
theme2.typography.h2.color = "#31A8F7";

const theme = createTheme({
  palette: {
    primary: {
      main: '#31A8F7',
      dark: '#088DE5',
      light: '#6AC0FA'
    },
  },
  typography: {
    h2: {
      fontSize: '1.5em',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1.5em',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1em',
      fontWeight: 'bold',
    }
  },
  components :{
    MuiLinks: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          textTransform: 'uppercase',
        }
      }
    }
  }
});

export default class PageLayout extends React.Component {

  render() {
    return (
      <CookiesProvider>
        <ThemeProvider theme={theme}>
        <GlobalStyles styles={{ h2: { color: '#111', textDecoration: 'none'} }} />
        <SiteAppBar />
  
        <Container>
        {this.props !== undefined && this.props.children}
        </Container >
        <Container sx={{mt:1, pt:1, mb:1, bt:1}} className="footer" >
          <Typography><a href="https://www.create.ab.ca/about-csaa/copyright/">© {(new Date()).getFullYear()} Creation Science Association of Alberta</a></Typography>
        </Container>
        </ThemeProvider>
      </CookiesProvider>
      
    )
  }
    
   
  }